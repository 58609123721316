import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  labeltag,
  portfolioimage01,
  portfolioimage02,
  portfolioimage03,
  portfolioimage04,
  portfolioimage05,
  portfolioimage06,
  portfolioimage07,
  portfolioimage08,
} from "src/assets";
const Index = () => {
  const tabItems = [
    { id: "tabs1", label: "See all", content: "Content for See all" },
    { id: "tabs2", label: "Analysis", content: "Content for Analysis" },
    { id: "tabs3", label: "Helpdesk", content: "Content for Helpdesk" },
    { id: "tabs4", label: "Marketing", content: "Content for Marketing" },
    { id: "tabs5", label: "Technology", content: "Content for Technology" },
  ];

  const [activeTab, setActiveTab] = useState(tabItems[0].id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <main className="bg-height4 bg-kpo-bpo our-portfolio1">
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-12 text-center top-banner">
                <h1 className="heading-01">Our Portfolio</h1>
                <div className="row justify-content-center mt-4">
                  <div className="col-lg-8 col-md-11 col-sm-12">
                    <p className="p-text">
                      Showcasing Success: Explore Our Impactful IT Solutions
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-item-section">
          <div className="container">
            <div className="menu-tabs">
              <div className="row">
                <div className="side-tabe">
                  <ul className="mobile-tabs" role="tablist">
                    {tabItems.map((tab) => (
                      <li key={tab.id} role="presentation">
                        <a
                          className={`tab-link ${
                            activeTab === tab.id ? "active" : ""
                          }`}
                          id={tab.id}
                          href={`#${tab.id}`}
                          title={`${tab.label} tab`}
                          role="tab"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent default anchor behavior
                            handleTabClick(tab.id);
                          }}
                        >
                          {tab.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div className="tab-content">
                    {tabItems.map(
                      (tab) =>
                        activeTab === tab.id && (
                          <div key={tab.id} className="tab-pane">
                            {tab.content}
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="right-menu">
                  <div className="tab-panel">
                    <div
                      className="tab-pane active tab-content"
                      id="see-all"
                      role="tabpanel"
                    >
                      {/* <div class="menu-title tabes1-bg">
                  <h2>See-all</h2>
               </div>  */}
                      <div className="row g-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card">
                            <figure>
                              <img className="w-100" src={portfolioimage01} />
                            </figure>
                            <div className="label-tag">
                              <p>
                                <img src={labeltag} /> Technology
                              </p>
                              <h2>
                                Driving Digital Transformation Explore the Depth
                                of Our IT Projects
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <Link to="/portfolios" className="card">
                            <figure>
                              <img
                                className="w-100"
                                src={portfolioimage02}
                                alt="Portfolio"
                              />
                            </figure>
                            <div className="label-tag">
                              <p>
                                <img src={labeltag} alt="Label" /> Technology
                              </p>
                              <h2>
                                Driving Digital Transformation Explore the Depth
                                of Our IT Projects
                              </h2>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane tab-content"
                      id="analysis"
                      role="tabpanel"
                    >
                      <div className="row g-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card">
                            <figure>
                              <img className="w-100" src={portfolioimage01} />
                            </figure>
                            <div className="label-tag">
                              <p>
                                <img src={labeltag} /> Technology
                              </p>
                              <h2>
                                Driving Digital Transformation Explore the Depth
                                of Our IT Projects
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card">
                            <figure>
                              <img className="w-100" src={portfolioimage02} />
                            </figure>
                            <div className="label-tag">
                              <p>
                                <img src={labeltag} /> Technology
                              </p>
                              <h2>
                                Driving Digital Transformation Explore the Depth
                                of Our IT Projects
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane tab-content"
                      id="helpdesk"
                      role="tabpanel"
                    >
                      <div className="row g-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card">
                            <figure>
                              <img className="w-100" src={portfolioimage03} />
                            </figure>
                            <div className="label-tag">
                              <p>
                                <img src={labeltag} /> Technology
                              </p>
                              <h2>
                                Driving Digital Transformation Explore the Depth
                                of Our IT Projects
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card">
                            <figure>
                              <img className="w-100" src={portfolioimage04} />
                            </figure>
                            <div className="label-tag">
                              <p>
                                <img src={labeltag} /> Technology
                              </p>
                              <h2>
                                Driving Digital Transformation Explore the Depth
                                of Our IT Projects
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane tab-content"
                      id="marketing"
                      role="tabpanel"
                    >
                      <div className="row g-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card">
                            <figure>
                              <img className="w-100" src={portfolioimage05} />
                            </figure>
                            <div className="label-tag">
                              <p>
                                <img src={labeltag} /> Technology
                              </p>
                              <h2>
                                Driving Digital Transformation Explore the Depth
                                of Our IT Projects
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card">
                            <figure>
                              <img className="w-100" src={portfolioimage06} />
                            </figure>
                            <div className="label-tag">
                              <p>
                                <img src={labeltag} /> Technology
                              </p>
                              <h2>
                                Driving Digital Transformation Explore the Depth
                                of Our IT Projects
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane tab-content"
                      id="technology"
                      role="tabpanel"
                    >
                      <div className="row g-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card">
                            <figure>
                              <img className="w-100" src={portfolioimage07} />
                            </figure>
                            <div className="label-tag">
                              <p>
                                <img src={labeltag} /> Technology
                              </p>
                              <h2>
                                Driving Digital Transformation Explore the Depth
                                of Our IT Projects
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card">
                            <figure>
                              <img className="w-100" src={portfolioimage08} />
                            </figure>
                            <div className="label-tag">
                              <p>
                                <img src={labeltag} /> Technology
                              </p>
                              <h2>
                                Driving Digital Transformation Explore the Depth
                                of Our IT Projects
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
