// This is for graphics page
import Slider from "react-slick";
import {
  bottomline,
  Branding,
  Branding1,
  Branding3,
  Branding4,
  Branding5,
  brandingbusiness,
  graphicicon01,
  graphicicon02,
  graphicicon03,
  graphicicon04,
  graphicicon05,
  graphicicon06,
  graphicicon07,
  graphicicon08,
  Graphics003,
  Graphics01,
  Graphics02,
  Illustrations36,
  Motionicon1,
  print_svgrepo12,
  socialmedia455,
  webdesignicon1,
  webdesignicon2,
  webdesignicon3,
} from "src/assets";
const Graphics = () => {
  const settings3 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
        },
      },
    ],
  };
  const settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
    ],
  };
  return (
    <>
      <main className="bg-height4 bg-grapphic02">
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-12 text-center top-banner">
                <h1 className="heading-01">Graphics Design</h1>
                <div className="row justify-content-center mt-4">
                  <div className="col-lg-8 col-md-11 col-sm-12">
                    <p className="p-text">
                      Graphics That Strengthen Your Brand Identity
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-3">
                <a
                  href="#"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit"></i>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="about-section">
          <div className="container">
            <div className="row g-3 justify-content-between" data-aos="fade-up">
              <div className="col-lg-5 col-12 col-md-12">
                <div className="row">
                  <h2 className="heading-02 lh-sm">
                    <span
                      className="position-relative d-inline-block"
                      data-aos="fade-left"
                    >
                      Why Choose Us
                      {/* <img src="./src/assets/img/bottom-line.svg" alt="line" */}
                      <img
                        src={bottomline}
                        alt="line"
                        className="position-absolute start-0 top-100 text-bottom-line w-100"
                      />
                    </span>
                  </h2>
                </div>
                <p className="text-light mt-3">
                  Level up your brand’s visuals, and enhance your identity with
                  creative graphic design services that make an impact.
                </p>
              </div>
              <div className="col-lg-6 col-md-12 col-12 d-flex justify-content-center align-items-center">
                <p className="text-light ">
                  Our approach combines artistic creativity and strategic
                  thinking. We collaborate closely with you to understand your
                  brand essence and target audience. By infusing emotion,
                  creativity, and purpose into each design, we create visuals
                  that not only grab attention but also connect with your
                  audience on a deeper level.
                </p>
              </div>
            </div>
            <div className="image-text-box understand55">
              <div
                className="row g-3 matop align-items-center"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <div className="col-lg-5 col-md-12 col-sm-12 text-center">
                  <img className="img-fluid" src={Graphics01} />
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <h2 className="heading-02 lh-sm">
                    <span
                      className="position-relative d-inline-block"
                      data-aos="fade-left"
                    >
                      We understand that every business is unique.
                    </span>
                  </h2>
                  <p>
                    Our team of seasoned designers brings fresh ideas to every
                    project. That’s why we offer tailored design services to
                    meet your specific needs and help you achieve your goals. We
                    pride ourselves on delivering high-quality designs on time,
                    every time. Our efficient process ensures you get what you
                    need when you need it. We believe in working closely with
                    our clients. Your feedback is invaluable, and we incorporate
                    it at every stage to ensure the final product exceeds your
                    expectations. Quality design shouldn't break the bank. We
                    offer competitive rates without compromising on creativity
                    or quality, making top-tier design accessible for all. From
                    logo design and branding to marketing materials and digital
                    graphics, we offer a full range of services to cover all
                    your design needs in one place.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="bg-color-box heightbg5"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <div className="row align-items-center revers-col">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <h2>Weaving Emotion And Creativity Into Every Design</h2>
                  <p>
                    Our approach combines artistic creativity and strategic
                    thinking. We collaborate closely with you to understand your
                    brand essence and target audience. By infusing emotion,
                    creativity, and purpose into each design, we create visuals
                    that not only grab attention but also connect with your
                    audience on a deeper level.
                  </p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                  <img className="img-fluid" src={Graphics02} alt="line" />
                </div>
              </div>
            </div>

            <div className="row iconCardBox-container grapich-box56 justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <div className="img-icon aos-init">
                    <img
                      src={brandingbusiness}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH">Visual Branding</h5>
                  <p className="text-gray text-center">
                    We create a visually cohesive brand identity, encompassing
                    logos, color palettes, typography, and brand guidelines that
                    evoke the essence of your brand.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <div className="img-icon aos-init">
                    <img src={print_svgrepo12} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">
                    Print And Digital Collateral
                  </h5>
                  <p className="text-gray text-center">
                    From brochures to social media graphics, we design stunning
                    collateral that aligns with your brand and amplifies your
                    messaging across various platforms.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <div className="img-icon aos-init">
                    <img src={socialmedia455} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Social Media Creatives</h5>
                  <p className="text-gray text-center">
                    Elevate your online presence with captivating and shareable
                    social media creatives that drive engagement and boost brand
                    visibility
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <div className="img-icon aos-init">
                    <img src={Motionicon1} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Motion Graphics</h5>
                  <p className="text-gray text-center">
                    Engage and captivate your audience with our dynamic motion
                    graphics, conveying your brand's message through captivating
                    animations.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <div className="img-icon aos-init">
                    <img src={Illustrations36} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">
                    Illustrations and Infographics
                  </h5>
                  <p className="text-gray text-center">
                    We bring concepts to life through custom illustrations and
                    infographics, simplifying complex information and enhancing
                    the understanding and engagement of your audience.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 text-center d-flex justify-content-center my-4">
              <a
                href="#"
                className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
              >
                Start Your Project{" "}
                <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit"></i>
              </a>
            </div>

            <div
              className="row g-3 justify-content-between why45top"
              data-aos="fade-up"
            >
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                  <h5 className="text-WH font-weight-normal">
                    Why You Choose Us?
                  </h5>
                  <h2 className="heading-02">
                    The Impact of Compelling Design
                  </h2>
                  <p className="text-WH">
                    Compelling graphic design has the power to ignite brand
                    engagement, create an emotional connection, and establish a
                    memorable brand presence. By leveraging the art of visual
                    storytelling, we help you captivate your audience, build
                    brand loyalty, and drive meaningful interactions.
                  </p>
                </div>
                <div className="liste-icon2">
                  <div className="row">
                    <div className="col-3">
                      <div className="round-icon1">
                        <img
                          src={webdesignicon1}
                          alt="icon"
                          className="mx-auto"
                        />
                      </div>
                    </div>
                    <div className="col-9">
                      <h4>Creative graphic design</h4>
                      <p>
                        Creative graphic design has the power to ignite brand
                        engagement, create an emotional connection, and
                        establish a memorable brand presence.
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <div className="round-icon1">
                        <img
                          src={webdesignicon2}
                          alt="icon"
                          className="mx-auto"
                        />
                      </div>
                    </div>
                    <div className="col-9">
                      <h4>Emotional Connection</h4>
                      <p>
                        Creative graphic design has the power to ignite brand
                        engagement, create an emotional connection, and
                        establish a memorable brand presence.
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <div className="round-icon1">
                        <img
                          src={webdesignicon3}
                          alt="icon"
                          className="mx-auto"
                        />
                      </div>
                    </div>
                    <div className="col-9">
                      <h4>Brand Presence</h4>
                      <p>
                        Creative graphic design has the power to ignite brand
                        engagement, create an emotional connection, and
                        establish a memorable brand presence.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12">
                <img src={Graphics003} alt="icon" className="w-100" />
              </div>
            </div>
          </div>
        </section>

        <section className="portflio5 logoSlider-aboutPage">
          <div className="container">
            <div className="row text-WH">
              <h2>A Portfolio of Our Best Work</h2>
              <p>Our Creative Footprint</p>
            </div>

            <div className="row mt-4">
              <div className="col-12 text-center list-img4">
                <Slider {...settings3}>
                  <div className="item-img">
                    <a href="#">
                      <img className="w-100" src={Branding} alt="" />
                      <div className="row">
                        <h3>Branding</h3>
                        <p>+200k download</p>
                      </div>
                    </a>
                  </div>
                  <div className="item-img">
                    <a href="#">
                      <img className="w-100" src={Branding1} alt="" />
                      <div className="row">
                        <h3>Branding</h3>
                        <p>+200k download</p>
                      </div>
                    </a>
                  </div>
                  <div className="item-img">
                    <a href="#">
                      <img className="w-100" src={Branding3} alt="" />
                      <div className="row">
                        <h3>Branding</h3>
                        <p>+200k download</p>
                      </div>
                    </a>
                  </div>
                  <div className="item-img">
                    <a href="#">
                      <img className="w-100" src={Branding4} alt="" />
                      <div className="row">
                        <h3>Branding</h3>
                        <p>+200k download</p>
                      </div>
                    </a>
                  </div>
                  <div className="item-img">
                    <a href="#">
                      <img className="w-100" src={Branding5} alt="" />
                      <div className="row">
                        <h3>Branding</h3>
                        <p>+200k download</p>
                      </div>
                    </a>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        {/* </div> */}

        <section className="logoSlider-section logoSlider-aboutPage">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 ">
                <h2 className="heading-06 lh-sm text-center text-WH">
                  The Things
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                    data-aos-duration="900"
                  >
                    We Do Best
                    <img
                      src={bottomline}
                      alt="line/"
                      className="position-absolute start-0 top-100 text-bottom-line w-100"
                    />
                  </span>
                </h2>
              </div>
              <div className="col-xl-8 col-lg-12 mt-5 text-center">
                <p
                  className="p-text fw-medium text-WH "
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Our Areas of Expertise
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center code-logo-slider">
              <Slider {...settings2}>
                <div>
                  <img
                    className="mx-auto"
                    src={graphicicon01}
                    alt="LARAVEL LOGO"
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={graphicicon02}
                    alt="HTML LOGO"
                  />
                </div>
                <div>
                  <img className="mx-auto" src={graphicicon03} alt="PHP LOGO" />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={graphicicon04}
                    alt="WORDPRESS LOGO"
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={graphicicon05}
                    alt="REACT LOGO"
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={graphicicon06}
                    alt="JAVASCRIPT LOGO"
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={graphicicon07}
                    alt="LARAVEL LOGO"
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={graphicicon08}
                    alt="HTML LOGO"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        {/* </div> */}
      </main>
    </>
  );
};

export default Graphics;
