const Index = () => {
  return (
    <div>
      <section className="business-mail-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h2 className="heading-02">Let's lift up your business!</h2>
              <p className="heading-05">
                Ready to start creating an amazing business?
              </p>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-8 col-12">
              <form className="row">
                <div className="col-12">
                  <input
                    type="email"
                    placeholder="E-mail"
                    className="formInput"
                  />
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="fill-btn outline-btn2 w-100 rounded-pill"
                  >
                    Get a Quote
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
