// src/ScreenshotsDeck.js

import { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import "./style.css";
import screen1 from "../../assets/img/screen1.png";
import screen2 from "../../assets/img/screen2.png";
import screen3 from "../../assets/img/screen3.png";

const screenshots = [screen1, screen2, screen3];

const imageStyles = [
  {
    zIndex: 1,
  },
  {
    zIndex: 2,
    marginLeft: "-79px",
  },
  {
    zIndex: 3,
    marginLeft: "-83px",
  },
];

const ScreenshotsDeck = () => {
  const controls = useAnimation();
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start((i) => ({
              opacity: 1,
              y: i === 1 ? -100 : 0,
              x: i * 130 - 50,
              scale: 1,
              transition: {
                duration: 0.5,
                delay: i * 0.3,
              },
            }));
          } else {
            controls.start((i) => ({
              opacity: 0,
              y: 50,
              x: 0,
              scale: 0.8,
              rotate: 0,
              transition: {
                duration: 0.5,
                delay: i * 0.1,
              },
            }));
          }
        });
      },
      { threshold: 0.2 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [controls]);

  return (
    <div className="screenshot-deck-container" ref={containerRef}>
      {screenshots.map((src, index) => (
        <motion.img
          key={index}
          src={src}
          alt={`Screenshot ${index + 1}`}
          className="screenshot img-fluid"
          custom={index}
          initial={{ opacity: 0, y: 50, x: 10, scale: 0.8, rotate: 0 }}
          animate={controls}
          style={imageStyles[index]}
        />
      ))}
    </div>
  );
};

export default ScreenshotsDeck;
