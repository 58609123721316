// CategoriesList.tsx
import React from "react";

interface CategoriesListProps {
  categories: { [key: string]: number };
}

const CategoriesList: React.FC<CategoriesListProps> = ({ categories }) => {
  return (
    <div className="row categories2">
      <h3>Categories</h3>
      <div className="categories-list text-WH">
        {Object.entries(categories)?.map(([categoryName, count]) => (
          <div className="row" key={categoryName}>
            <div className="col-9 p-0">
              <p>{categoryName}</p>
            </div>
            <div className="col-3 text-end">
              <p>({count})</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesList;
