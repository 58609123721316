import { Link } from "react-router-dom";
import { logo } from "src/assets";

const Footer = () => {
  return (
    <div>
      <footer className="overflow-hidden ">
        <div className="container">
          <div className="row footer-cols-contain">
            <div className="col-xl-3 col-lg-3 col-md-12 col-12">
              <Link to="/">
                <img style={{ width: "72%" }} src={logo} alt="logo" />
              </Link>
              <small>
                Innovative solutions designed to supercharge your online
                presence! Customized for your specific needs and shaped by your
                feedback.
              </small>
              <div className="nav-media d-flex mt-2">
                <a href="https://www.instagram.com/ditinus/?hl=en">
                  <i className="bi bi-instagram text-WH me-1 px-2 py-1" />
                </a>
                <a href="https://www.facebook.com/ditinus/">
                  <i className="bi bi-facebook text-WH me-1 px-2 py-1" />
                </a>
                <a href="https://www.linkedin.com/company/ditinus-technology-pvt-ltd/?originalSubdomain=in">
                  <i className="bi bi-linkedin text-WH me-1 px-2 py-1" />
                </a>
              </div>
            </div>
            <div className="py-3 py-lg-0 py-md-2 col-xl-2 col-lg-2 col-md-3 col-6">
              <h5>Services </h5>
              <ul className="list-unstyled p-0">
                <li>
                  <Link to="/ui-ux" className="text-decoration-none transition">
                    Design
                  </Link>
                </li>
                <li>
                  <Link
                    to="/software-development"
                    className="text-decoration-none transition"
                  >
                    Developent
                  </Link>
                </li>
                <li>
                  <Link
                    to="/digital-marketing"
                    className="text-decoration-none transition"
                  >
                    Digital Marketing
                  </Link>
                </li>
                {/* <li>
                  <Link to="/ui-ux" className="text-decoration-none transition">
                    UI UX
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="py-3 py-lg-0 py-md-2 col-xl-2 col-lg-2 col-md-3 col-6">
              <h5>Support</h5>
              <ul className="list-unstyled p-0">
                <li>
                  <Link
                    to="/contact-us"
                    className="text-decoration-none transition"
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <a href="#" className="text-decoration-none transition">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="text-decoration-none transition">
                    Refund Policy
                  </a>
                </li>
                <li>
                  <Link to="/blogs" className="text-decoration-none transition">
                    Blogs
                  </Link>
                </li>
              </ul>
            </div>
            <div className="py-3 py-lg-0 py-md-2 col-xl-2 col-lg-2 col-md-3 col-6">
              <h5>Resources</h5>
              <ul className="list-unstyled p-0">
                <li>
                  <a
                    href="/case-study"
                    className="text-decoration-none transition"
                  >
                    Case Studies
                  </a>
                </li>
                <li>
                  <a href="#" className="text-decoration-none transition">
                    Recent Projects
                  </a>
                </li>
              </ul>
            </div>
            <div className="py-3 py-lg-0 py-md-2 col-xl-2 col-lg-3 col-md-3 col-6 footer-address">
              <h5>Address</h5>
              <div className="col-12 d-flex flex-column gap-3">
                <div className="icon-box d-flex align-items-center">
                  <i className="bi bi-telephone-fill me-2 text-denim" />
                  <div className="d-flex flex-column">
                    <a href="#">+91 991 557 8189</a>
                  </div>
                </div>
                <div className="icon-box d-flex align-items-center">
                  <i className="bi bi-envelope-fill me-2 text-denim" />
                  <div className="d-flex flex-column">
                    <a href="#">business@ditinustechnology.com</a>
                  </div>
                </div>
                <div className="icon-box d-flex align-items-center">
                  <i className="bi bi-geo-alt-fill me-2 text-denim" />
                  <div className="d-flex flex-column">
                    <a href="#">
                      Plot E-302, Vista Tower, Industrial Area, Sector 75,
                      Sahibzada Ajit Singh Nagar, Punjab 160055
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12  py-xl-3 py-2 text-center copyright">
          <small>
            © <span className="text-denim">Ditinus</span> All Right Reserved.
          </small>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
