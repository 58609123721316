import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { behance, logo, upwork } from "src/assets";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    const navTabs = document.querySelector(".navigation");
    const toggleClass = "is-sticky";

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (navTabs) {
        if (currentScroll > 150) {
          navTabs.classList.add(toggleClass);
        } else {
          navTabs.classList.remove(toggleClass);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <header className="d-flex align-items-center justify-content-center w-100 header flex-column navigation  ">
        <nav className="navbar navbar-expand-lg container">
          <Link
            to="/"
            className="web-nav-logo-tab text-decoration-none text-denim fs-4"
          >
            <img src={logo} alt="img" />
          </Link>
          <div className="web-nav-links w-100 align-items-center justify-content-center d-flex menu-header">
            <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center">
              {/* <li className="nav-item">
                <Link className="nav-link" to="/home">
                  Home
                </Link>
              </li> */}

              <li className="nav-item dropdown-link">
                <Link className="nav-link" to="/">
                  Company <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul style={{ padding: "9px" }}>
                  <li>
                    <Link className="nav-link" to="/about-us">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/training">
                      Training
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/career">
                      Career
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/blogs">
                      Blogs
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="about-us">
                  About Us
                </Link>
              </li> */}
              <li className="nav-item dropdown-link">
                <Link className="nav-link" to="/services">
                  Services <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul style={{ width: "200px", padding: "9px" }}>
                  <li>
                    <Link className="nav-link" to="/ui-ux">
                      Design
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/software-development">
                      Developent
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/digital-marketing">
                      Digital Marketing
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="nav-link" to="/ui-ux">
                      UI UX
                    </Link>
                  </li> */}
                  <li>
                    <Link className="nav-link" to="/graphics">
                      Graphics
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="nav-link" to="/kpo-bpo">
                      KPO BPO
                    </Link>
                  </li> */}
                </ul>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/portfolio">
                  Portfolio
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/case-study">
                  Case Study
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="desktop-view d-flex flex-row ms-auto gap-3 align-items-center">
            <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item dropdown-link ">
                <Link
                  style={{
                    width: "115px",
                    padding: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    color: "white",
                  }}
                  className="nav-link"
                  to=""
                >
                  Hire Us On <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul
                  style={{
                    width: "87px",
                    padding: "2px",
                    gap: "3px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <li>
                    <Link className="nav-link" to="/">
                      <img src={upwork} alt="logo" />
                    </Link>
                  </li>

                  <li>
                    <Link className="nav-link" to="/">
                      <img src={behance} alt="logo" />
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            <div className="d-flex px-2">
              <Link
                to="/kpo-bpo"
                className="px-4 py-2 transition  text-nowrap rounded-pill fill-btn  "
              >
                KPO/BPO
              </Link>
            </div>
          </div>
          <button
            onClick={toggleMenu}
            className="web-nav-toggle border-0 p-0 text-white toggleMenuBar "
          >
            <i className="bi bi-list display-3 d-flex align-items-center " />
          </button>
        </nav>
      </header>

      <div className={`bg-shadow-cover ${isMenuOpen ? "showShadow" : ""} `} />
      <div
        className={`mobile-nav-sidebar navbar-toggler ${
          isMenuOpen ? "showMenu" : ""
        }`}
      >
        <div className="cover-mobile-menu">
          <div className="mobile-logo-header d-flex">
            <Link
              className="web-nav-logo text-decoration-nonepx-4 text-denim fs-4"
              to="/"
            >
              <img src={logo} alt="img" />
            </Link>
            <button
              type="button"
              onClick={toggleMenu}
              className="text-hover-gold ms-auto toggleBtnClose bg-transparent border-0"
            >
              <i className="bi bi-x-lg text-dark fs-1" />
            </button>
          </div>
          <div className="mobile-menu-body py-0 flex-column d-flex">
            <ul className="navbar-nav mb-2 mb-lg-0 mobileNavigation">
              <li className="nav-item">
                <Link
                  className="nav-link text-WH text-hover-black"
                  to="/"
                  onClick={toggleMenu}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-WH text-hover-black"
                  to="/about-us"
                  onClick={toggleMenu}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item dropdown-link">
                <Link className="nav-link text-WH text-hover-black" to="#">
                  Services <i className="bi bi-chevron-down ms-1" />
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="nav-link text-WH text-hover-black"
                      to="/ui-ux"
                      onClick={toggleMenu}
                    >
                      Design
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link text-WH text-hover-black"
                      to="/software-development"
                      onClick={toggleMenu}
                    >
                      Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link text-WH text-hover-black"
                      to="/digital-marketing"
                      onClick={toggleMenu}
                    >
                      Digital Marketing
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link text-WH text-hover-black"
                      to="/ui-ux"
                      onClick={toggleMenu}
                    >
                      UI/UX
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link text-WH text-hover-black"
                      to="/graphics"
                      onClick={toggleMenu}
                    >
                      Graphics
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      className="nav-link text-WH text-hover-black"
                      to="/kpo-bpo"
                      onClick={toggleMenu}
                    >
                      KPO/BPO
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-WH text-hover-black"
                  to=""
                  onClick={toggleMenu}
                >
                  Why Choose Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-WH text-hover-black"
                  to="/blogs"
                  onClick={toggleMenu}
                >
                  Blog
                </Link>
              </li>
            </ul>
            <div className="mobile-menu-btns d-flex flex-column gap-3">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#gitInTouch"
                className="px-4 py-2 transition text-nowrap rounded-pill fill-btn"
                onClick={toggleMenu}
              >
                Free Consultation
              </button>
              <div className="d-flex">
                <Link
                  to="/contact-us"
                  className="transition text-center text-nowrap rounded-pill fill-btn outline-btn w-100"
                  onClick={toggleMenu}
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
