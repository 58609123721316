import React from "react";
import {
  labeltag,
  portfoliodetails,
  portfolioimage07,
  portfolioimage08,
  profilepic,
  qutesiocn,
  ux04,
} from "src/assets";

const Index = () => {
  return (
    <div>
      <main className="bg-height4 bg-kpo-bpo our-portfolio1 poortfolio-single-page">
        <section className="container">
          <div className="row">
            <h1 className="heading-01">
              Driving Digital Transformation Explore the Depth of Our IT
              Projects
            </h1>
          </div>
        </section>
        <section className="our-portfoliotext">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <figure>
                  {" "}
                  <img className="w-100" src={portfoliodetails} />
                </figure>
              </div>
            </div>
            <div className="row g-3 pt-3">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="heading-02">
                  Network Infrastructure and Design
                </h2>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
                <p>
                  The "Cloud Migration and Integration Project" represents a
                  pivotal step for our client's digital transformation journey.
                  By transitioning from traditional on-premises infrastructure
                  to cloud-based solutions, we are unlocking a world of
                  possibilities, from scalability and flexibility to enhanced
                  security and cost efficiency. Our meticulous planning and
                  execution ensure a smooth migration process, with minimal
                  disruption to business operations. From assessing the current
                  environment to preparing the infrastructure, migrating data
                  and applications.
                </p>
                <p>
                  providing comprehensive training and support, we leave no
                  stone unturned in ensuring the success of this project. With
                  our expertise and dedication, we empower our client to embrace
                  the full potential of cloud computing, driving innovation.
                </p>
              </div>
              <hr style={{ color: "#CCE3FF" }} />
              <div className="name-tag-12">
                <p>
                  <span>Services:</span> Cloud Migration
                </p>
                <p>
                  <span>Client:</span> Techco
                </p>
                <p>
                  <span>Location:</span> New York,NY,USA
                </p>
                <p>
                  <span>Completed Date:</span> 20-12-2024
                </p>
              </div>
            </div>
            <div className="row p-ding2 align-items-center">
              <div className="col-lg-4 col-md-4 col-sm-12 ">
                <div className="round-img">
                  <figure>
                    {" "}
                    <img className="img-thumbnail " src={profilepic} />
                  </figure>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-md-10 col-sm-12">
                    <div className="row">
                      <div className="col-12 pb-4">
                        <img src={qutesiocn} />
                      </div>
                      <div className="col-12 text-WH">
                        <p>
                          Excellent work on the web development project! The
                          design is clean and user-friendly, and everything
                          works smoothly across devices.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3 p-ding2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="heading-02">Project Requirement</h2>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
                <p>
                  In this phase of the Cloud Migration and Integration project,
                  our focus is on executing robust data migration strategies to
                  ensure the seamless transfer of data from on-premises servers
                  to cloud storage solutions. Leveraging advanced techniques and
                  tools,
                </p>
              </div>
            </div>
            <div className="row p-ding2 text-WH ">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-1">
                    <img className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Comprehensive Assessment Phase</p>
                  </div>
                  <div className="col-1">
                    <img className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Strategic Migration Plan Development</p>
                  </div>
                  <div className="col-1">
                    <img className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Robust Data Migration Strategies</p>
                  </div>
                  <div className="col-1">
                    <img className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Infrastructure Preparation</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-1">
                    <img className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Application Migration</p>
                  </div>
                  <div className="col-1">
                    <img className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Training and Documentation</p>
                  </div>
                  <div className="col-1">
                    <img className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Infrastructure Preparation</p>
                  </div>
                  <div className="col-1">
                    <img className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Post-migration Support</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3 p-ding2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="heading-02">Solution &amp; Result</h2>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
                <p>
                  The successful execution of robust data migration strategies
                  ensures the seamless transfer of data from on-premises servers
                  to cloud storage solutions. Data integrity, security, and
                  regulatory compliance are prioritized throughout the migration
                  process. Rigorous testing and validation verify the accuracy
                  and completeness of data migration, minimizing downtime and
                  data loss risks.
                </p>
                <p>
                  To achieve successful data migration, our solution includes a
                  comprehensive approach that encompasses meticulous planning,
                  advanced techniques, and thorough testing. We leverage
                  industry-leading tools and expertise.
                </p>
              </div>
            </div>
            <div className="row g-3 p-ding2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="heading-02">Solution &amp; Result</h2>
              </div>
              <div className="right-menu">
                <div className="row g-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <figure>
                        <img className="w-100" src={portfolioimage07} />
                      </figure>
                      <div className="label-tag">
                        <p>
                          <img src={labeltag} /> Technology
                        </p>
                        <h2>
                          Driving Digital Transformation Explore the Depth of
                          Our IT Projects
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <figure>
                        <img className="w-100" src={portfolioimage08} />
                      </figure>
                      <div className="label-tag">
                        <p>
                          <img src={labeltag} /> Technology
                        </p>
                        <h2>
                          Driving Digital Transformation Explore the Depth of
                          Our IT Projects
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
