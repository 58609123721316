import Slider from "react-slick";
import ScreenshotsDeck from "../../components/screenDeck";
import {
  adobe_xd_icon,
  Bootstrap_logo,
  bottomline,
  bpoicon,
  client,
  clientapproachicon,
  customizedicon,
  digitalmarketingicon,
  DitinuslogoBg,
  Dot,
  dotnet,
  expertsleverageicon,
  expertteamicon,
  figma_logo_icon,
  Google,
  htmllogo,
  javascriptlogo2,
  Laravellogo,
  Nodejs_logo,
  phplogo,
  provenresultsicon,
  quote,
  reactlogo2,
  softdevelopmenticon,
  transparentreporticon,
  webdesignicon,
  wordpresslogo2,
} from "../../assets";
import ParallaxText from "../../components/ParallaxText";
import FloatingMedia from "src/components/FloatingMedia";

// servicesContentBox
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 1281,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 866,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
      },
    },
  ],
};

const settings2 = {
  dots: false,
  infinite: true,
  speed: 20000,
  slidesToShow: 7,
  slidesToScroll: 7,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 100,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    },
  ],
};

const settings3 = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 8,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        infinite: true,
      },
    },
  ],
};

const Home = () => {
  return (
    <main>
      <FloatingMedia />
      <section id="home" className="hero-section bg-img">
        {/* <div class="top-right-ring">
<img src="./src/assets/img/top-ring.png" class="w-100 h-100 object-fit" alt="img">
</div>
<div class="left-bottom-ring">
<img src="./src/assets/img/left-ring.png" class="w-100 h-100 object-fit" alt="img">
</div> */}
        <div className="ditinus-loop-text text-center ">
          <span>
            <img src={DitinuslogoBg} alt="logo" />
          </span>
        </div>
        <div className="container h-100 position-relative">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col-12 text-center">
              <h1 className="heading-07 text-WH fw-medium mb-3 text-center lh-base">
                <strong className=" fw-bold heading-03">
                  Powerful IT Solutions For
                  <br />
                </strong>
                Accelerated Business Success!
              </h1>
              <p className="text-WH fs-5 text-center">
                Push through the complexities of the digital dilemma as we take
                you across innovative strategies, advanced technology,
                <br />
                and personalized solutions tailored to your needs.
              </p>
              <div className="d-flex flex-row justify-content-center hero-banner-btns">
                <a
                  href="#"
                  type="button"
                  className="transition text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3" />
                </a>
                <button
                  type="button"
                  className="transition text-nowrap rounded-pill fill-btn outline-btn"
                >
                  Quick Contact
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="bg-white marquee-logo-wall-01">
        <div className="marquee-logo-slider marquee-slider-01">
          <div className=" marquee-logo-slider ">
            <Slider {...settings2}>
              <div className="d-flex flex-nowrap align-items-center">
                <img src={Google} alt="img" className="logoImg" />
                <img src={Dot} alt="img" className="blueDot" />
              </div>
              <div className="d-flex flex-nowrap align-items-center">
                <img src={Google} alt="img" className="logoImg" />
                <img src={Dot} alt="img" className="blueDot" />
              </div>
              <div className="d-flex flex-nowrap align-items-center">
                <img src={Google} alt="img" className="logoImg" />
                <img src={Dot} alt="img" className="blueDot" />
              </div>
              <div className="d-flex flex-nowrap align-items-center">
                <img src={Google} alt="img" className="logoImg" />
                <img src={Dot} alt="img" className="blueDot" />
              </div>
              <div className="d-flex flex-nowrap align-items-center">
                <img src={Google} alt="img" className="logoImg" />
                <img src={Dot} alt="img" className="blueDot" />
              </div>
              <div className="d-flex flex-nowrap align-items-center">
                <img src={Google} alt="img" className="logoImg" />
                <img src={Dot} alt="img" className="blueDot" />
              </div>
              <div className="d-flex flex-nowrap align-items-center">
                <img src={Google} alt="img" className="logoImg" />
                <img src={Dot} alt="img" className="blueDot" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="bg-white marquee-logo-wall-02">
        <div className=" marquee-logo-slider ">
          <Slider {...settings2}>
            <div className="d-flex flex-nowrap align-items-center">
              <img src={Google} alt="img" className="logoImg" />
              <img src={Dot} alt="img" className="blueDot" />
            </div>
            <div className="d-flex flex-nowrap align-items-center">
              <img src={Google} alt="img" className="logoImg" />
              <img src={Dot} alt="img" className="blueDot" />
            </div>
            <div className="d-flex flex-nowrap align-items-center">
              <img src={Google} alt="img" className="logoImg" />
              <img src={Dot} alt="img" className="blueDot" />
            </div>
            <div className="d-flex flex-nowrap align-items-center">
              <img src={Google} alt="img" className="logoImg" />
              <img src={Dot} alt="img" className="blueDot" />
            </div>
            <div className="d-flex flex-nowrap align-items-center">
              <img src={Google} alt="img" className="logoImg" />
              <img src={Dot} alt="img" className="blueDot" />
            </div>
            <div className="d-flex flex-nowrap align-items-center">
              <img src={Google} alt="img" className="logoImg" />
              <img src={Dot} alt="img" className="blueDot" />
            </div>
            <div className="d-flex flex-nowrap align-items-center">
              <img src={Google} alt="img" className="logoImg" />
              <img src={Dot} alt="img" className="blueDot" />
            </div>
          </Slider>
        </div>
      </div> */}
      <div className="hero-banner-media">
        <ul className="m-0 p-0 list-unstyled m-0 p-0 d-flex justify-content-center">
          <li>
            <a
              href="#"
              className="rounded-3 fill-btn outline-btn px-2 rounded-circle"
            >
              <i className="bi bi-whatsapp" />
            </a>
          </li>
          <li>
            <a
              href="#"
              className="rounded-3 fill-btn outline-btn px-2 rounded-circle"
            >
              <i className="bi bi-telephone-fill " />
            </a>
          </li>
          <li>
            <a
              href="#"
              className="rounded-3 fill-btn outline-btn px-2 rounded-circle"
            >
              <i className="bi bi-envelope-fill" />
            </a>
          </li>
        </ul>
      </div>
      <section className="our-work-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="d-flex gap-1 flex-column">
                <h4 className="heading-01 text-uppercase text-WH d-flex justify-content-center">
                  70M <span className="fs-2 d-flex">+</span>
                </h4>
                <p className="text-WH text-center">
                  Total revenue generated from <br /> portfolio
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6  ">
              <div className="d-flex gap-1 flex-column">
                <h4 className="heading-01 text-uppercase text-WH d-flex justify-content-center">
                  20 <span className="fs-2 d-flex">+</span>
                </h4>
                <p className="text-WH text-center">
                  Total revenue generated from <br /> portfolio
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6  ">
              <div className="d-flex gap-1 flex-column">
                <h4 className="heading-01 text-uppercase text-WH d-flex justify-content-center">
                  700 <span className="fs-2 d-flex">+</span>
                </h4>
                <p className="text-WH text-center">
                  Total revenue generated from <br /> portfolio
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="heading-02 lh-sm">
                <span
                  className="position-relative d-inline-block"
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  About Us
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100"
                  />
                </span>
              </h2>
            </div>
            <div className="col-xl-7 col-lg-7 col-12 d-flex justify-content-start align-items-center">
              <h4 className="fw-bold heading-08 text-WH">
                Innovative IT Solutions in India <br />
                <span className="fw-normal">
                  Our Story, Our Zeal, Our Promise !
                </span>
              </h4>
            </div>
            <div className="col-xl-5 col-lg-5 col-12 d-flex justify-content-center align-items-center">
              <p className="p-text ">
                Unlock the potential for fast business growth with the best IT
                company in India. Discover how we work to take your business
                Forward!
              </p>
            </div>
          </div>
          <div className="row justify-content-between about-img-content">
            <div className="col-12 col-xl-5 col-lg-5 col-md-12 ">
              <ScreenshotsDeck />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 mt-5">
              <h5 className="heading-05 text-denim">
                Turning awesome Ideas Into Reality!
              </h5>
              <h4 className="heading-06 fw-bold ">
                Stay Ahead in The Digital Realm <br />
                <span className="fw-normal">Turn Ideas Into Reality.</span>
              </h4>
              <p className="text-WH mt-4 lh-lg">
                Staying ahead of the competition in today’s digital era is
                crucial for propelling fast success. But with so much
                competition online, how can you ensure your business stands out
                and becomes the talk of the town?
                <br /> Shall we cut to the chase? we’re not exactly
                mathematicians over here, but we’re experts at solving the
                equation of success for our clients! Break down the equation of
                your business growth with the best IT company in India!
              </p>
              <a
                href="#"
                className="px-4 py-2 transition text-WH bg-pink text-nowrap rounded-pill fill-btn outline-btn "
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="services-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-10 col-12 text-center">
              <h2 className="heading-06 lh-sm pl-2">
                Our
                <span
                  className="position-relative d-inline-block"
                  style={{ paddingLeft: "8px" }}
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  Services
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100 object-fit-contain"
                  />
                </span>
              </h2>
              <h4 className="heading-08">
                Our Core IT Services For Maximum Digital Impact.
              </h4>
              <p className="text-WH p-text fw-normal">
                Leverage the power of innovative IT solutions and precisely
                reach your target audience with the best IT company in India.
              </p>
            </div>
          </div>
          <div className="row serviceBox-Contain">
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 serviceBox"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="servicesContentBox">
                <div
                  className="img-icon"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img src={webdesignicon} alt="icon" />
                </div>
                <h5 className="p-text18 fw-bold text-WH">Web Designing</h5>
                <p className="text-WH p-text">
                  Break away from the ordinary! Our web design company in India
                  is the perfect partner for crafting user-oriented and friendly
                  interfaces that captivate your audiences.
                </p>
                <a
                  href="#"
                  target="_blank"
                  className="text-denim iconBtn mt-auto"
                >
                  Read More... <i className="bi bi-arrow-up-short ms-1" />
                </a>
              </div>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 serviceBox"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="servicesContentBox">
                <div
                  className="img-icon"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img src={softdevelopmenticon} alt="icon" />
                </div>
                <h5 className="p-text18 fw-bold text-WH">
                  Software Development Services
                </h5>
                <p className="text-WH p-text">
                  Experience the magic of cutting-edge solutions with our
                  exceptional software development services in India. From
                  responsive design to seamless functionality, we deliver
                  websites that captivate your audience.
                </p>
                <a
                  href="#"
                  target="_blank"
                  className="text-denim iconBtn mt-auto"
                >
                  Read More... <i className="bi bi-arrow-up-short ms-1" />
                </a>
              </div>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 serviceBox"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="servicesContentBox">
                <div
                  className="img-icon"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img src={digitalmarketingicon} alt="icon" />
                </div>
                <h5 className="p-text18 fw-bold text-WH">
                  Digital Marketing Services
                </h5>
                <p className="text-WH p-text">
                  Step into the spotlight and get your business known among the
                  crowd with the best digital marketing company in India. Our
                  carefully planned strategies, from SEO and PPC to Social Media
                  Marketing, ensure tangible outcomes for your business in the
                  long run.
                </p>
                <a
                  href="#"
                  target="_blank"
                  className="text-denim iconBtn mt-auto"
                >
                  Read More... <i className="bi bi-arrow-up-short ms-1" />
                </a>
              </div>
            </div>
            {/* <div
              className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="servicesContentBox">
                <div
                  className="img-icon"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img src={bpoicon} alt="icon" />
                </div>
                <h5 className="p-text18 fw-bold text-WH">KPO/BPO Services</h5>
                <p className="text-WH p-text">
                  Get the most out of our business with our expert KPO/BPO
                  services in India. From effectively managing the data to
                  seamless customer support, we provide efficient outsourcing
                  solutions tailored to your needs.
                </p>
                <a
                  href="#"
                  target="_blank"
                  className="text-denim iconBtn mt-auto"
                >
                  Read More... <i className="bi bi-arrow-up-short ms-1" />
                </a>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12 text-center d-flex justify-content-center mt-3">
              <a
                href="#"
                type="button"
                className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
              >
                Our Services{" "}
                <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="whyChooseUs-section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-12">
              <h2 className="heading-06 lh-sm">
                <span
                  className="position-relative d-inline-block"
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  Why Choose Us
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100"
                  />
                </span>
              </h2>
            </div>
            <div className="col-xl-7 col-lg-7 col-12 d-flex justify-content-start align-items-center">
              <h4 className="fw-bold heading-08">
                Why Choose Ditinus Technology as <br />
                <span className="fw-normal">
                  Your Go-To IT Company in India?
                </span>
              </h4>
            </div>
            <div className="col-xl-5 col-lg-5 col-12 d-flex justify-content-center align-items-center">
              <p className="p-text ">
                We won’t boast about ourselves. Instead, we’ll show you how we
                drive your business growth with our expertise and cutting-edge
                technology. Let’s simplify success together.
              </p>
            </div>
          </div>
          <div className="row mt-4 design-text whyChooseUs-cards">
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={expertteamicon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">Expert Team</h5>
              <p className="text-WH p-text">
                Our Team of experienced professionals stays ahead of the curve
                with the digital marketing trends and best practices.
              </p>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={customizedicon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">
                Customized Strategies
              </h5>
              <p className="text-WH p-text">
                Our IT company in India takes the time to identify the goals and
                objectives of your brand and leverage the latest technologies
                and trends.
              </p>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={provenresultsicon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">Proven Results</h5>
              <p className="text-WH p-text">
                We have a track record of delivering measurable results, Helping
                businesses grow their online presence.
              </p>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={transparentreporticon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">
                Cutting-Edge Technology
              </h5>
              <p className="text-WH p-text">
                We use cutting-edge technologies needed to stay ahead. Leverage
                the power of innovation with the best IT company in India!
              </p>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={clientapproachicon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">
                Client-Centric Approach
              </h5>
              <p className="text-WH p-text">
                We take the time to understand your needs and customize plans
                accordingly, deemed the most suitable for maximum growth.
              </p>
            </div>
            <div
              className="col-12 col-xl-4 col-lg-4 col-md-6 design-text-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div
                className="img-icon mx-auto rounded-pill text-center"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src={expertsleverageicon}
                  alt="icon"
                  className="my-auto mx-auto"
                />
              </div>
              <h5 className="p-text18 fw-bold text-WH">Reliability</h5>
              <p className="text-WH p-text">
                Our IT company in India is all about leaving our customers
                satisfied with long-term and tangible outcomes.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="logoSlider-section logoSlider-aboutPage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="heading-02 lh-sm text-center text-WH">
                The Things
                <span
                  className="position-relative d-inline-block"
                  style={{ marginLeft: "5px" }}
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  We Do Best
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100"
                  />
                </span>
              </h2>
            </div>
            <div className="col-xl-8 col-lg-12 mt-3 text-center">
              <p
                className="p-text fw-medium text-WH "
                data-aos="fade-up"
                data-aos-duration={900}
                style={{ fontWeight: 500 }}
              >
                Our Areas of Expertise
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center code-logo-slider">
            <Slider {...settings3}>
              <div>
                <img
                  className="mx-auto"
                  src={Laravellogo}
                  alt="LARAVEL LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={500}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={htmllogo}
                  alt="HTML LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={900}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={phplogo}
                  alt="PHP LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1300}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={wordpresslogo2}
                  alt="WORDPRESS LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1600}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={reactlogo2}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={adobe_xd_icon}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={Bootstrap_logo}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={dotnet}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={Nodejs_logo}
                  alt="REACT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1800}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={javascriptlogo2}
                  alt="JAVASCRIPT LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={2100}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={Laravellogo}
                  alt="LARAVEL LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={500}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={htmllogo}
                  alt="HTML LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={900}
                />
              </div>
              <div>
                <img
                  className="mx-auto"
                  src={phplogo}
                  alt="PHP LOGO"
                  data-aos="zoom-in-left"
                  data-aos-duration={1300}
                />
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="testimonial-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 ">
              <h2 className="heading-22 lh-sm text-WH d-flex justify-content-center">
                <span
                  className="position-relative d-inline-block"
                  data-aos="fade-left"
                  data-aos-duration={900}
                >
                  Our Testimonials
                  <img
                    src={bottomline}
                    alt="line"
                    className="position-absolute start-0 top-100 text-bottom-line w-100"
                  />
                </span>
              </h2>
            </div>
            <div className="col-xl-8 col-lg-12 mt-2 ">
              <p
                className="p-text fw-medium text-WH text-center"
                data-aos="fade-up"
                data-aos-duration={900}
              >
                We don’t just follow the latest marketing trends – we set them.
                We’re not afraid to take risks, <br />
                and we’re always willing to try new things in order to help our
                clients stand <br />
                out in a crowded marketplace.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="testimonial testimonial-slider">
              <div className="container-fluid">
                <div className="row">
                  <div className="testimonial  testimonial-slider">
                    <Slider {...settings}>
                      <div className="review-box">
                        <div className="review-content">
                          <div className="review-img-content d-flex">
                            <img src={client} alt="img" />
                          </div>
                          <div className="review-description">
                            <div className="quote-img">
                              <img src={quote} alt="img" />
                            </div>
                            <small>
                              We have been working with Ankur and his team for
                              the last 3 years. The experience has been flawless
                              and they regularly deliver beyond our
                              expectations. They built us an amazing website
                              which they continue to maintain. We have been on
                              the top on Google for all our major keywords with
                              the help of their SEO services. I’d highly
                              recommend to definitely talk to
                            </small>
                            <div className="review-title">
                              <hr />
                              <div>
                                <h5>Lorem ipsum dolor sit ame.</h5>
                                <p>Lorem ipsum dolor sit ame.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="review-box">
                        <div className="review-content">
                          <div className="review-img-content d-flex">
                            <img src={client} alt="img" />
                          </div>
                          <div className="review-description">
                            <div className="quote-img">
                              <img src={quote} alt="img" />
                            </div>
                            <small>
                              We have been working with Ankur and his team for
                              the last 3 years. The experience has been flawless
                              and they regularly deliver beyond our
                              expectations. They built us an amazing website
                              which they continue to maintain. We have been on
                              the top on Google for all our major keywords with
                              the help of their SEO services. I’d highly
                              recommend to definitely talk to
                            </small>
                            <div className="review-title">
                              <hr />
                              <div>
                                <h5>Lorem ipsum dolor sit ame.</h5>
                                <p>Lorem ipsum dolor sit ame.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="review-box">
                        <div className="review-content">
                          <div className="review-img-content d-flex">
                            <img src={client} alt="img" />
                          </div>
                          <div className="review-description">
                            <div className="quote-img">
                              <img src={quote} alt="img" />
                            </div>
                            <small>
                              We have been working with Ankur and his team for
                              the last 3 years. The experience has been flawless
                              and they regularly deliver beyond our
                              expectations. They built us an amazing website
                              which they continue to maintain. We have been on
                              the top on Google for all our major keywords with
                              the help of their SEO services. I’d highly
                              recommend to definitely talk to
                            </small>
                            <div className="review-title">
                              <hr />
                              <div>
                                <h5>Lorem ipsum dolor sit ame.</h5>
                                <p>Lorem ipsum dolor sit ame.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="review-box">
                        <div className="review-content">
                          <div className="review-img-content d-flex">
                            <img src={client} alt="img" />
                          </div>
                          <div className="review-description">
                            <div className="quote-img">
                              <img src={quote} alt="img" />
                            </div>
                            <small>
                              We have been working with Ankur and his team for
                              the last 3 years. The experience has been flawless
                              and they regularly deliver beyond our
                              expectations. They built us an amazing website
                              which they continue to maintain. We have been on
                              the top on Google for all our major keywords with
                              the help of their SEO services. I’d highly
                              recommend to definitely talk to
                            </small>
                            <div className="review-title">
                              <hr />
                              <div>
                                <h5>Lorem ipsum dolor sit ame.</h5>
                                <p>Lorem ipsum dolor sit ame.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="review-box">
                        <div className="review-content">
                          <div className="review-img-content d-flex">
                            <img src={client} alt="img" />
                          </div>
                          <div className="review-description">
                            <div className="quote-img">
                              <img src={quote} alt="img" />
                            </div>
                            <small>
                              We have been working with Ankur and his team for
                              the last 3 years. The experience has been flawless
                              and they regularly deliver beyond our
                              expectations. They built us an amazing website
                              which they continue to maintain. We have been on
                              the top on Google for all our major keywords with
                              the help of their SEO services. I’d highly
                              recommend to definitely talk to
                            </small>
                            <div className="review-title">
                              <hr />
                              <div>
                                <h5>Lorem ipsum dolor sit ame.</h5>
                                <p>Lorem ipsum dolor sit ame.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
