// Search.tsx
import React from "react";

interface SearchProps {
  onSearch: (query: string) => void;
}

const Search: React.FC<SearchProps> = ({ onSearch }) => {
  const [query, setQuery] = React.useState<string>("");

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(query);
  };

  return (
    <div className="row mob-hidden">
      <h3>Search</h3>
      <form className="blog-search" onSubmit={handleSearch}>
        <div className="search-box">
          <input
            type="text"
            className="search-input"
            placeholder="Search....."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button type="submit" className="search-button">
            <i className="bi bi-search" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search;
