import {
  callicon,
  facebooklogo,
  instagramlogo,
  linkedinlogo,
  locationicon,
  logox,
  mailicon,
  questionicon,
  sign,
  whatsapplogo,
} from "../../assets/index";

const Index = () => {
  return (
    <div>
      <main>
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-6 col-lg-8 col-md-12 col-12 text-center">
                <h1 className="heading-01">Contact Us</h1>
                <p className="p-text">
                  Listen, we’re not exactly mathematicians over here, but we’re
                  experts at solving the equation of success for our clients!
                </p>
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-5">
                <a
                  href="#"
                  type="button"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-info">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                <img src={sign} alt="img" />
              </div>
              <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                <h2 className="fs-6 text-WH">Have questions or inquiries?</h2>
                <h4 className="heading-02">
                  Reach out to us for personalized{" "}
                  <span className="text-green">assistance and support.</span>
                </h4>
                <h4 className="heading-06 fw-normal">
                  We’re here to help you every step of the way.
                </h4>
                <h5 className="heading-02 fw-bold">Ditinus Technology,</h5>
                <h5 className="heading-05 fw-normal">
                  E-302, Phase 8A, Vista Tower, Mohali, Punjab
                </h5>
                <div className="media-icon d-flex ">
                  <img src={facebooklogo} alt="facebook logo" />
                  <img src={instagramlogo} alt="instagram logo" />
                  <img src={linkedinlogo} alt="linkedin logo" />
                  <img src={whatsapplogo} alt="whatsapp logo" />
                  <img src={logox} alt="whatsapp logo" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contactForm-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                <form className="contact-form col-inner-box">
                  <div className="col-12 text-center mb-5">
                    <h4 className="heading-04">Let’s Talk</h4>
                    <p className="p-text">Get more done, faster-together</p>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      className="formInput"
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="Email"
                      placeholder="Email"
                      className="formInput"
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="number"
                      placeholder="Number"
                      className="formInput"
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      placeholder="Message"
                      className="formInput"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="fill-btn outline-btn2 border-0 text-uppercase"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                <div className="col-inner-box info-company">
                  <div className="d-flex w-100 align-items-center warning-box ">
                    <img
                      src={questionicon}
                      alt="icon"
                      className="object-fit-contain me-2"
                    />
                    <p className="text-WH mb-2">
                      The fastest way to reach us is to{" "}
                      <span className="text-dark-blue">send us message.</span>{" "}
                    </p>
                  </div>
                  <div>
                    <span className="heading-08 fw-medium">
                      <img
                        style={{ paddingRight: "10px" }}
                        src={mailicon}
                        alt="icon"
                      />
                      Email
                    </span>
                    <p className="heading-08 fw-normal">
                      <strong className="fw-medium">Business Inquiry:</strong>{" "}
                      <a href="mailto:business@ditinustechnology.com">
                        business@ditinustechnology.com
                      </a>
                    </p>
                    <p className="heading-08 fw-normal">
                      <strong className="fw-medium">HR Inquiry:</strong>{" "}
                      <a href="mailto:hr@ditinustechnology.com">
                        hr@ditinustechnology.com
                      </a>
                    </p>
                  </div>
                  <div>
                    <span className="heading-08 fw-medium">
                      <img
                        src={callicon}
                        alt="icon"
                        style={{ paddingRight: "10px" }}
                      />
                      Phone
                    </span>
                    <p className="heading-08 fw-normal">
                      <strong className="fw-medium">Business Phone:</strong>{" "}
                      <a href="callto:+919915578189">+91 991 557 8189</a>
                    </p>
                    <p className="heading-08 fw-normal">
                      <strong className="fw-medium">HR Phone:</strong>{" "}
                      <a href="callto:+916239382356">+91 623 938 2356</a>
                    </p>
                  </div>
                  <div>
                    <span className="heading-08 fw-medium">
                      <img
                        src={locationicon}
                        alt="icon"
                        style={{ paddingRight: "10px" }}
                      />
                      Office Address
                    </span>
                    <p className="heading-08 fw-normal">
                      Mohali <br />
                      <strong className="fw-bold">
                        Ditinus Technology Pvt Ltd.
                      </strong>{" "}
                      <br />
                      E-302, Phase 8A,
                      <br /> Vista Tower,
                      <br /> Mohali, Punjab
                      <br /> India
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="location-map">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13721.732367589504!2d76.67965970711168!3d30.7062228435495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef85bcf88ab3%3A0x520191891f8d816d!2sDitinus%20Technology%20Private%20Ltd.%20Mohali!5e0!3m2!1sen!2sin!4v1724237123562!5m2!1sen!2sin"
                  width="100%"
                  height={600}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
