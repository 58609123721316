import "./index.css";
import "./assets/css/slick.css";
import "./assets/css/bootstrap.css";
import "./assets/css/animation.css";
import "./assets/css/style.css";

import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Services from "./Pages/Services";
import Blogs from "./Pages/Blogs";
import ContactUs from "./Pages/ContactUs";
import SingleBlog from "./Pages/SingleBlog";
import UIUX from "./Pages/UIUX";
import DigitalMaketing from "./Pages/DigitalMarketing";
import Development from "./Pages/Development";
import Graphics from "./Pages/Graphics";
import Portfolio from "./Pages/Portfolio";
import KpoBpo from "./Pages/KpoBpo";
import Portfolios from "./Pages/SinglePortfolio";
import CaseStudy from "./Pages/CaseStudy";
import Design from "./Pages/Design";
import Career from "./Pages/Career";
import Training from "./Pages/Training";
import GetAQute from "./components/GetAQoute";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import AppProviders from "./AppProviders";
import { Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <AppProviders>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/ui-ux" element={<UIUX />} />
        <Route path="/digital-marketing" element={<DigitalMaketing />} />
        <Route path="/software-development" element={<Development />} />
        <Route path="/graphics" element={<Graphics />} />
        <Route path="/kpo-bpo" element={<KpoBpo />} />
        <Route path="/case-study" element={<Portfolio />} />
        <Route path="/portfolios" element={<Portfolios />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/training" element={<Training />} />
        {/* <Route path="/case-study" element={<CaseStudy />} /> */}
        <Route path="/design" element={<Design />} />
        <Route path="/career" element={<Career />} />
        <Route path="/blog/:slug" element={<SingleBlog />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <GetAQute />
      <Footer />
    </AppProviders>
  );
};

export default App;
