import { Link } from "react-router-dom";
import { calendaricon41, sms12, uerprofi45 } from "src/assets";
import axios from "axios";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import Search from "src/components/SearchBar";
import RecentPosts from "src/components/RecentPosts";
import CategoriesList from "src/components/CategoriesList";
import TagsList from "src/components/TagsList";
import { Circles } from "react-loader-spinner";
import ComingSoon from "src/components/ComingSoon";
const Index = () => {
  const [blogs, setBlogs] = useState<any[]>([]);
  const [filteredBlogs, setFilteredBlogs] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [visibleCount, setVisibleCount] = useState<number>(4);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<{ [key: string]: number }>({});
  const API_URL = process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  const fetchBlogs = async () => {
    try {
      const response = await api.get("/blogs");
      return response?.data;
    } catch (error) {
      console.error("Error fetching blogs:", error);
      setError(
        "Failed to fetch blogs. Please check your network connection and API URL."
      );
      throw error;
    }
  };

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags");
      return response?.data;
    } catch (error) {
      console.error("Error fetching tags:", error);
      setError(
        "Failed to fetch tags. Please check your network connection and API URL."
      );
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [blogData, tagData] = await Promise.all([
          fetchBlogs(),
          fetchTags(),
        ]);

        setBlogs(blogData);
        setFilteredBlogs(blogData);

        const categoryCount: { [key: string]: number } = {};
        blogData?.forEach((blog) => {
          blog?.Categories.forEach((category) => {
            if (categoryCount[category?.name]) {
              categoryCount[category?.name]++;
            } else {
              categoryCount[category?.name] = 1;
            }
          });
        });
        setCategories(categoryCount);

        // Set tags
        setTags(tagData);
      } catch (error) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false); // Set loading to false once data fetching is complete
      }
    };

    fetchData();
  }, []);

  const handleSearch = (query: string) => {
    if (!query) {
      setFilteredBlogs(blogs);
      return;
    }

    const lowercasedQuery = query.toLowerCase();
    const filtered = blogs.filter(
      (blog) =>
        blog.title.toLowerCase().includes(lowercasedQuery) ||
        blog.excerpt.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredBlogs(filtered);
  };

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };
  return (
    <div>
      <ComingSoon />
      {/* <div>
        <div>
          <main className="bg-height4 bg-kpo-bpo blog-list1">
            <section className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-12 text-center top-banner">
                  <h1 className="heading-01">Our Blog</h1>
                  <p className="p-text">
                    Showcasing Success: Explore Our Impactful IT Solutions
                  </p>
                </div>
              </div>
            </section>
          </main>
        </div>
        <section className="blog-row">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="col-12">
                  <div className="row dek-hidden mb-4">
                    <form className="blog-search">
                      <div className="search-box">
                        <input
                          type="text"
                          className="search-input"
                          placeholder="Search....."
                        />
                        <button className="search-button">
                          <i className="bi bi-search" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="blog-list-row">
                  {blogs?.map((blog: any) => (
                    <div key={blog?.id} className="blog-item" data-filter="*">
                      <div className="right-menu p-0">
                        <div className="row g-4">
                          <div className="col-12">
                            <div className="card text-WH">
                              <figure>
                                <img
                                  className="w-100"
                                  src={
                                    `${API_URL}/blog/photo/${blog?.slug}` ||
                                    "default-image-url"
                                  }
                                  alt={blog?.title}
                                />
                              </figure>
                              <div className="label-tag">
                                <p>
                                  <img src={uerprofi45} alt="User" />{" "}
                                  {blog?.User?.username || "Admin"}
                                </p>
                                <p>
                                  <img
                                    src={sms12}
                                    style={{ width: 30 }}
                                    alt="Comments"
                                  />{" "}
                                  Comments <span>(0)</span>
                                </p>
                                <p>
                                  <img src={calendaricon41} alt="Date" />{" "}
                                  {new Date(
                                    blog?.createdAt
                                  ).toLocaleDateString()}
                                </p>
                              </div>
                              <div className="col-12">
                                <h4 className="heading-04">{blog?.title}</h4>
                                <p>{parse(blog?.excerpt)}</p>
                              </div>
                              <div className="btn-readmore">
                                <Link
                                  to={`/blog/${blog?.slug}`}
                                  className="transition text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                                >
                                  Read More{" "}
                                  <i className="bi bi-arrow-up-short ms-1 fs-3" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12">
                {loading ? (
                  <Circles
                    height="80"
                    width="80"
                    color="#0D72B8"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=" "
                    visible={true}
                  />
                ) : (
                  <div className="side-blog">
                    <Search onSearch={handleSearch} />

                    <RecentPosts
                      blogs={filteredBlogs}
                      visibleCount={visibleCount}
                      onShowMore={handleShowMore}
                      error={error}
                    />
                    <CategoriesList categories={categories} />
                    <TagsList tags={tags} error={error} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div> */}
    </div>
  );
};

export default Index;
