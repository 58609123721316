import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const FloatingMedia = ({ scrollThreshold = 100 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [scrollThreshold]);

  useEffect(() => {
    if (isVisible) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [isVisible, controls]);

  if (isMobile) return null; // Hide component on mobile screens

  return (
    <motion.div
      className="floating-media position-fixed bottom-0 end-0 mb-3 me-3 w-auto"
      style={{ zIndex: "9999" }}
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <ul className="list-unstyled p-0 m-0">
        <li className="mb-2">
          <a
            href="#"
            className="btn btn-outline-primary rounded-circle fill-btn outline-btn d-flex justify-content-center align-items-center"
            style={{ height: "50px", width: "50px" }}
          >
            <i className="bi bi-whatsapp" style={{ fontSize: "22px" }}></i>
          </a>
        </li>
        <li className="mb-2">
          <a
            href="#"
            className="btn btn-outline-primary rounded-circle fill-btn outline-btn d-flex justify-content-center align-items-center"
            style={{ height: "50px", width: "50px" }}
          >
            <i
              className="bi bi-telephone-fill"
              style={{ fontSize: "22px" }}
            ></i>
          </a>
        </li>
        <li className="mb-2">
          <a
            href="#"
            className="btn btn-outline-primary rounded-circle fill-btn outline-btn d-flex justify-content-center align-items-center"
            style={{ height: "50px", width: "50px" }}
          >
            <i className="bi bi-envelope-fill" style={{ fontSize: "22px" }}></i>
          </a>
        </li>
      </ul>
    </motion.div>
  );
};

export default FloatingMedia;
