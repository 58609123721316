import axios from "axios";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import {
  blogimg01,
  bloglist1,
  calendaricon41,
  faceb4,
  linkicon1,
  uerprofi45,
} from "src/assets";
import Search from "src/components/SearchBar";
import RecentPosts from "src/components/RecentPosts";
import CategoriesList from "src/components/CategoriesList";
import TagsList from "src/components/TagsList";

interface Category {
  id: number;
  name: string;
  slug: string;
}

interface Tag {
  id: number;
  name: string;
  slug: string;
}

interface User {
  id: number;
  username: string;
}

interface Blog {
  id: number;
  title: string;
  body: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  Categories: Category[];
  Tags: Tag[];
  User: User;
}

const Index = () => {
  const { slug } = useParams<{ slug: string }>();
  const [blog, setBlog] = useState<Blog | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [blogs, setBlogs] = useState<any[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [visibleCount, setVisibleCount] = useState<number>(4);
  const [categories, setCategories] = useState<{ [key: string]: number }>({});

  const API_URL = process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL: API_URL,
  });
  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };

  const fetchBlogs = async () => {
    try {
      const response = await api.get("/blogs");
      return response?.data;
    } catch (error) {
      console.error("Error fetching blogs:", error);
      throw error;
    }
  };

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogData = await fetchBlogs();
        setBlogs(blogData);
        const categoryCount: { [key: string]: number } = {};
        blogData?.forEach((blog) => {
          blog?.Categories.forEach((category) => {
            if (categoryCount[category?.name]) {
              categoryCount[category?.name]++;
            } else {
              categoryCount[category?.name] = 1;
            }
          });
        });
        setCategories(categoryCount);
      } catch (error) {
        setError("Failed to fetch blogs");
      }
    };

    getBlogs();
  }, []);

  useEffect(() => {
    const fetchBlog = async () => {
      if (!slug) return;

      try {
        const url = `${API_URL}/blog/${slug}`;
        const response = await axios.get<Blog>(url);
        const blogData = response?.data;
        setBlog(blogData);

        // Transform Categories array to the required format
        const categoriesObject = blogData.Categories.reduce<{
          [key: string]: number;
        }>((acc, category) => {
          acc[category?.name] = (acc[category?.name] || 0) + 1;
          return acc;
        }, {});
        setCategories(categoriesObject);

        setTags(blogData?.Tags);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error("Fetch Blog Error:", error.message);
        } else {
          console.error("Unexpected Error:", error);
        }
        setError("Failed to fetch blog");
      }
    };

    fetchBlog();
  }, [slug, API_URL]);

  if (error) return <p>{error}</p>;
  if (!blog) return <p>Loading...</p>;

  return (
    <div>
      <main className="bg-height4 bg-kpo-bpo blog-single12">
        {blog && (
          <section className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-12 text-center top-banner">
                <h1 className="heading-01">{blog?.title}</h1>
              </div>
            </div>
          </section>
        )}
      </main>
      <section className="blog-row">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="blog-list-row">
                <div className="blog-item" data-filter="*">
                  <div className="right-menu p-0">
                    <div className="row g-4">
                      <div className="col-12">
                        <div className="card text-WH">
                          <figure>
                            <img
                              className="w-100"
                              src={
                                `${API_URL}blog/photo/${blog?.slug}` ||
                                "default-image-url"
                              }
                              alt={blog?.title}
                            />
                          </figure>
                          <div className="label-tag">
                            <p>
                              <img src={uerprofi45} alt="User profile" />{" "}
                              {blog?.User?.username}
                            </p>
                            <p>
                              <img src={calendaricon41} alt="Calendar icon" />{" "}
                              {new Date(blog?.createdAt).toLocaleDateString()}
                            </p>
                          </div>
                          <div className="col-12">
                            <p>{parse(blog?.body)}</p>
                          </div>
                          <hr style={{ color: "#CCE3FF" }} />
                          <div className="row side-blog p-0 tag-g">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="pt-235 flex2">
                                <div className="col3">
                                  <h3>Tag</h3>
                                </div>
                                <div className="col4">
                                  <div className="name-tag-12">
                                    {tags?.map((tag) => (
                                      <p key={tag.id}>
                                        <a href="#">{tag?.name}</a>
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="pt-235 flex2">
                                <div className="col3">
                                  <h3>Share</h3>
                                </div>
                                <div className="col4">
                                  <div className="name-tag-12 social-media4">
                                    <p>
                                      <a
                                        href="https://www.facebook.com/sharer/sharer.php?u=YOUR_URL_HERE"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="Share on Facebook"
                                      >
                                        <img
                                          src={faceb4}
                                          width={11}
                                          alt="Facebook"
                                        />
                                      </a>
                                    </p>
                                    <p>
                                      <a
                                        href="https://twitter.com/intent/tweet?url=YOUR_URL_HERE"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="Share on Twitter"
                                      >
                                        <i className="bi bi-twitter-x" />
                                      </a>
                                    </p>
                                    <p>
                                      <a
                                        href="https://www.linkedin.com/sharing/share-offsite/?url=YOUR_URL_HERE"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="Share on LinkedIn"
                                      >
                                        <img
                                          src={linkicon1}
                                          width={18}
                                          alt="LinkedIn"
                                        />
                                      </a>
                                    </p>
                                    <p>
                                      <a
                                        href="https://pinterest.com/pin/create/button/?url=YOUR_URL_HERE"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="Share on Pinterest"
                                      >
                                        <i className="bi bi-pinterest" />
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="side-blog">
                <Search
                  onSearch={(query) => console.log("Search query:", query)}
                />
                <RecentPosts
                  blogs={blogs}
                  visibleCount={visibleCount}
                  onShowMore={handleShowMore}
                  error={error}
                />
                <CategoriesList categories={categories} />
                <TagsList tags={tags} error={error} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
