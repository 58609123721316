import React from 'react'
import { ComingSoonImg, DitinuslogoBg } from 'src/assets'

const ComingSoon = () => {
    return (
        <section id="home" className="hero-section soon-section" style={{ marginBottom: '5%', marginTop: '5%' }}>

            <div className="ditinus-coming-text text-center ">
                <span>
                    <img src={DitinuslogoBg} alt="logo" />
                </span>
            </div>
            <div className="container position-relative">
                <div className="row align-items-center justify-content-center h-100">
                    <div className="col-12 text-center">
                        <h1 className="heading-07 text-WH fw-medium mb-3 text-center lh-base">
                            <strong className=" fw-bold heading-03">
                                The site is currently
                                <br />
                                down for maintenance
                            </strong>

                        </h1>
                        <p className="text-WH fs-5 text-center">
                            We apologize for any maintenances caused.

                            <br />
                            We’ve almost done.
                        </p>

                    </div>



                </div>
            </div>
            <div className='ditinus-comingsoon container-fluid img-fill w-100 h-75'>

                <img style={{ width: '100%' }} src={ComingSoonImg} alt="" />
            </div>
        </section>
    )
}

export default ComingSoon